.modal {
  background: transparent;
  filter: blur(#e5e5e5);
}

.modal-body {
  background-color: #fff;
}

.modal-body h1 {
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
}

.modal-body a {
    color: #fff;
    text-decoration: none;
    
}

.modal-header {
  background-color: #fff;
}

.modal-header button.close {
  position: relative;
  padding: 0 1rem;
}

.modal__button:hover {
  color: #fff;
}
